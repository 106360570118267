/* Warning: please do not statically import any dependencies here except for static assets like icons */
/* eslint-disable import/no-anonymous-default-export */
import { HelpIcon } from '@panwds/icons'
import { _T } from './i18n'
import { SPARKY_NAV3_BOTTOM_LEFT_EXTPOINT } from '@sparky/framework/extpoints'
import type { ExtensionsExports, NavParams, SparkyFramework, StoreState, Vars } from '@sparky/framework'

const importComponent = () => import(/* webpackPreload: true */ './NavHelp')
const mapStateToProps = (state: StoreState, vars: Vars, fx: SparkyFramework) => {
  const coveo = state.coveo
  const predefinedToken = vars?.token as string | undefined
  const showMetaDataDropdown: boolean =
    fx.hasMicroApp('aiops-for-ngfw') &&
    Boolean(
      state.auth?.instances?.isRunningStatus('strata_insights') ||
        state.auth?.instances?.isRunningStatus('strata_cloud_manager')
    )
  return { predefinedToken, showMetaDataDropdown, coveo }
}

export const extensions: ExtensionsExports = ({ vars, sparkyFramework: fx }) => {
  if (!fx.getFrameworkVar('use_new_left_nav') || !fx.getFrameworkVar('load_nav_extensions')) {
    return []
  }
  return [
    {
      point: SPARKY_NAV3_BOTTOM_LEFT_EXTPOINT,
      configs: [
        {
          id: 'sparky.coveo.help',
          config: {
            key: 'coveo',
            title: _T('Help'),
            icon: HelpIcon,
            mapStateToProps: (state: StoreState) => Object.assign(mapStateToProps(state, vars, fx), { nav3: true }),
            navComponent: () =>
              importComponent().then(({ NavHelpSheet }) => ({
                default: NavHelpSheet,
              })),
          },
        },
      ],
    },
  ]
}

export default ({ vars, sparkyFramework: fx }: NavParams) => {
  return (
    fx.getFrameworkVar<boolean>('use_new_left_nav') &&
    fx.hasTsgSupport() && {
      actions: [
        {
          key: 'coveo',
          title: _T('Help'),
          navComponent: importComponent,
          mapStateToProps: (state: StoreState) => mapStateToProps(state, vars, fx),
          noRBACheck: true,
          icon: HelpIcon,
          avail: () => true,
          callAvail: 'override',
          _override_existing: true,
        },
      ],
    }
  )
}
